import React, { Component } from "react"
import "../assets/fonts/font.css"
import "../assets/css/faq.css"
import Footer from '../components/footer';
import NewsLatter from '../components/newsletter';

import lineImage from "../images/line2.png"
import Layout from "../components/layout"
import handIcon from "../images/hand.svg"
import chatbottom from "../images/chat-bottom-icon.svg"
import chatProfile from "../images/chat-profile.png"
import closeChat from "../images/close-icon.svg"
import SEO from "../components/seo"


class PrivacyPolicy extends Component {

  render() {
    return (
      <Layout rest={this.props} layoutType="main">
        <SEO title="Servicecontract.com - PRIVACY NOTICE" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
        <div className="chat-content" style={{ display: 'none' }}>
          <div className="chat-content-circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
              <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
                <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                  <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                  <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                  <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                  <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
                </g>
              </g>
            </svg>
          </div>
          <div className="chat-box">

            <div className="chatbox-inner">
              <div className="chat-profile">
                <img className="" src={chatProfile} alt="" />
              </div>
              <span className="close-chat"><img className="" src={closeChat} alt="" /></span>
              <p> <img className="hand-icon" src={handIcon} alt="" /> <span>Hi, We're here to help.</span></p>
            </div>
            <img className="chat-bottom" src={chatbottom} alt="" />
          </div>
        </div>

        <section className="faq-page-container terms-container">
          <div className="faq-tab-container">
            <div className="tab-container">
              <h2 className="tab-title">PRIVACY NOTICE</h2>
              <div className="tab-content prinote">
                
                
              <p><strong>Long Story Short</strong></p>
              <p>Thanks for trusting servicecontract.com with your vehicle and your information! We only require that you provide the minimum amount of information 
                necessary for us to determine the cost of your service contract and the details we need to provide that 
                information to you. We gather the basic information you give us and what we learn from your visit to our site, 
                and we and our affiliates and business partners use it to fulfill the purpose of your interaction with us and 
                as otherwise described in our Privacy Notice. Remember, our goal is to protect your vehicle and your privacy. 
                {/* Click here for the long story, that is, our full Privacy Policy / Notice. */}</p>
              <br /><p><strong>The Long Story </strong></p>
              <p>By visiting servicecontract.com and using its services, you are accepting the practices described in this Privacy Policy / Privacy Notice. </p>
              <p>Privacy Policy Notice<br />(Rev. 10-2019)</p>
              <br /><p> <strong>Introduction</strong></p>
              <p>Servicecontract.com respects your privacy and are committed to protecting it through our compliance with this policy.</p>
              <p>This policy describes the types of information we may collect from you or that you may provide when you visit the site servicecontract.com and our practices for collecting, using, maintaining, protecting, and disclosing that information.</p>
              <br /><p><strong>This policy applies to information we collect:</strong></p>
              <ul className="priul">
                <li>On this servicecontract.com</li>
                <li>In email, text, and other electronic messages between you and the site.</li>
                <li>Through mobile and desktop applications you download from the site, which provide dedicated non-browser-based interaction between you and the site.</li>
                <li>When you interact with our advertising and applications on third-party sites and services, if those applications or advertising include links to this policy.</li>
              </ul>
              <br /><p><strong>It does not apply to information collected by:</strong></p>
              <ul className="priul">
                <li>Us offline or through any other means, including on any other site operated by servicecontract.com or any third party (including our affiliates and subsidiaries); or </li>
                <li>Any third party (including our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from or on the site.</li>
              </ul>
              <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our site. By accessing or using this site, you agree to this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of this site after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates. </p>
              <br /><p> <strong>Children Under the Age of 16</strong></p>
              <p>Our site is not intended for children under 16 years of age. No one under age 16 may provide any personal information to or on the site. We do not knowingly collect personal information from children under 16. If you are under 16, do not use or provide any information on this site or through any of its features, register on the site, make any purchases through the site, use any of the interactive or public comment features of this site, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at help@servicecontract.com</p>
              <p>California residents under 16 years of age may have additional rights regarding the collection and sale of their personal information. Please see Your California Privacy Rights for more information.</p>
              <p> <strong>Information We Collect About You and How We Collect It</strong></p>
              <p>We collect several types of information from and about users of our site, including information:</p>
              <ul className="priul">
                <li>By which you may be personally identified, such as name, postal address, e-mail address, telephone number, information regarding your vehicle, or any other identifier by which you may be contacted online or offline (“personal information”);</li>
                <li>That is about you but individually does not identify you including information regarding your vehicle; and/or</li>
                <li>About your internet connection, the equipment you use to access our site, and usage details.</li>
              </ul>
              <p>We collect this information:</p>
              <ul className="priul">
                <li>Directly from you when you provide it to us.</li>
                <li>Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</li>
                <li>From third parties, for example, our business partners, subsidiaries, and affiliates.</li>
              </ul>
              <p><strong>Information You Provide to Us</strong>  </p>
              <p>The information we collect on or through our site may include:</p>
              <ul className="priul">
                <li>Information that you provide by filling in forms on our site. This includes but is not limited to information provided at the time of a quote, creating an account, registering to use our site, purchasing a service contract, or requesting further services from us. We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report a problem with our site.</li>
                <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
                <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
                <li>Details of transactions you carry out through our site and of the fulfillment of your purchases of service contracts. You may be required to provide financial information before placing an order through our site.</li>
                <li>Your search queries on the site.</li>
              </ul>
              <p>You also may provide information to be published or displayed (hereinafter, “posted”) on public areas of the site, or transmitted to other users of the site or third parties (collectively, “User Contributions”). Your User Contributions are posted on and transmitted to others at your own risk. Although we limit access to certain pages/you may set certain privacy settings for such information by logging into your account profile, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the site with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.</p>
              <p><strong>Information We Collect Through Automatic Data Collection Technologies</strong>  </p>
              <p>As you navigate through and interact with our site, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</p>
              <ul className="priul">
                <li> Details of your visits to our site, including traffic data, location data, logs, and other communication data and the resources that you access and use on the site.</li>
                <li>Information about your computer and internet connection, including your IP address, operating system, and browser type.</li>
              </ul>
              <p>We also may use these technologies to collect information about your online activities over time and across third-party sites or other online services (behavioral tracking). You may be able to opt out of behavioral tracking on this site and how we respond to web browser signals and other mechanisms that enable consumers to exercise choice about behavioral tracking.</p>
              <p>The information we collect automatically may include personal information or we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our site and to deliver a better and more personalized service, including by enabling us to:</p>
              <ul className="priul">
                <li>Estimate our audience size and usage patterns.</li>
                <li>Store information about your preferences, allowing us to customize our site according to your individual interests.</li>
                <li>Speed up your searches.</li>
                <li>Recognize you when you return to our site.</li>
              </ul>
              <p>The technologies we use for this automatic data collection may include:</p>
              <ul className="priul">
                <li><strong>Cookies (or browser cookies).</strong> A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our site. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our site. </li>
                <li><strong>Flash Cookies.</strong> Certain features of our site may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our site. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see Choices About How We Use and Disclose Your Information.</li>
                <li><strong>Web Beacons.</strong> Pages of our the site and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the servicecontract.com, for example, to count users who have visited those pages or opened an email and for other related site statistics (for example, recording the popularity of certain site content and verifying system and server integrity). </li>
              </ul>
              <p> <strong> Third-Party Use of Cookies and Other Tracking Technologies</strong></p>
              <p>Some content or applications, including advertisements, on the site are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our site. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different sites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content. </p>
              <p>We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see Choices About How We Use and Disclose Your Information.</p>
              <p> <strong>How We Use Your Information</strong></p>
              <p>We use information that we collect about you or that you provide to us, including any personal information:</p>
              <ul className="priul">
                <li>To present our site and its contents to you.</li>
                <li>To provide you with information, products, or services that you request from us.</li>
                <li>To fulfill any other purpose for which you provide it.</li>
                <li>To provide you with notices about your account/subscription, including term related notices.</li>
                <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
                <li>To notify you about changes to our site or any products or services we offer or provide though it.</li>
                <li>To allow you to participate in interactive features on our Site.</li>
                <li>In any other way we may describe when you provide the information.</li>
                <li>For any other purpose with your consent.</li>
              </ul>
              <p>We may also use your information to contact you about our own, our affiliates, and subsidiaries, and third-parties' goods and services that may be of interest to you. If you do not want us to use your information in this way, please adjust your user preferences in your account profile. For more information, see Choices About How We Use and Disclose Your Information.</p>
              <p>We may use the information we have collected from you to enable us to display advertisements to our advertisers' target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.</p>
              <p> <strong>Disclosure of Your Information</strong></p>
              <p>We may disclose aggregated information about our users, and information that does not identify any individual, without restriction. </p>
              <p>We may disclose personal information that we collect or you provide as described in this privacy policy:</p>
              <ul className="priul">
                <li>To our parent, subsidiaries, and affiliates.</li>
                <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
                <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of servicecontract.com Inc.’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by servicecontract.com, Inc. about our site users is among the assets transferred.</li>
                <li>To third parties to market their products or services to you if you have not opted out of these disclosures. We contractually require these third parties to keep personal information confidential and use it only for the purposes for which we disclose it to them. For more information, see Choices About How We Use and Disclose Your Information.</li>
                <li>To fulfill the purpose for which you provide it. For example, if you give us an email address to use the "email a friend" feature of our site, we will transmit the contents of that email and your email address to the recipients.</li>
                <li>For any other purpose disclosed by us when you provide the information.</li>
                <li>With your consent.</li>
              </ul>
              <p>We may also disclose your personal information:</p>
              <ul className="priul">
                <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
                <li>To enforce or apply our Terms of Use and Terms of Sale, and other agreements, including for billing and collection purposes.</li>
                <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of servicecontract.com, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
              </ul>
              <p> <strong>Choices About How We Use and Disclose Your Information</strong></p>
              <p>We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information: </p>
              <ul className="priul">
                <li><strong>Tracking Technologies and Advertising.</strong> You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe's site. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</li>
                <li><strong>Disclosure of Your Information for Third-Party Advertising.</strong> If you do not want us to share your personal information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by logging into the site and adjusting your user preferences in your account profile, checking or unchecking the relevant boxes or by sending us an email with your request to <a href="mailto:help@servicecontract.com"> help@servicecontract.com</a>. 
                </li>
                <li><strong>Promotional Offers from the servicecontract.com.</strong> If you do not wish to have your email address/contact information used by the servicecontract.com to promote our own, our affiliates, subsidiaries, or third parties' products or services, you can opt-out by logging into the site and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to  help@servicecontract.com. If we have sent you a promotional email, you may send us a return email asking to be omitted from future email distributions. This opt out does not apply to information provided to the servicecontract.com as a result of a product or service contract purchase, registration, product service experience or other transactions.</li>
                <li><strong>Targeted Advertising.</strong> If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers' target-audience preferences, you can opt-out by checking the relevant box. For this opt-out to function, you must have your browser set to accept all browser cookies. </li>
              </ul>
              <p>We do not control third parties' collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (“NAI”) on the NAI's site.</p>
              <p>California residents may have additional personal information rights and choices. Please see Your California Privacy Rights for more information.</p>
              <p>Nevada residents who wish to exercise their sale opt-out rights under Nevada Revised Statutes Chapter 603A may submit a request to <a href="mailto:help@servicecontract.com"> help@servicecontract.com</a>. However, please know we do not currently sell data triggering that statute's opt-out requirements.
              </p>
              <p> <strong>Accessing and Correcting Your Information</strong></p>
              <p>You can review and change your personal information by logging into the Site and visiting your account profile page.</p>
              <p>You may also send us an email at help@servicecontract.com to request access to, correct or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>
              <p>If you delete your User Contributions from the Site, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other Site users. Proper access and use of information provided on the Site, including User Contributions, is governed by our Terms of Use.</p>
              <p>California residents may have additional personal information rights and choices. Please see Your California Privacy Rights for more information.</p>
              <p> <strong>Your California Privacy Rights</strong></p>
              <p>If you are a California resident, California law may provide you with additional rights regarding our use of your personal information. To learn more about your California privacy rights, visit HYPERLINK TO CCPA PRIVACY NOTICE FOR CALIFORNIA RESIDENTS.</p>
              <p>California's "Shine the Light" law (Civil Code Section § 1798.83) permits users of our App that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to <a href="mailto:help@servicecontract.com"> help@servicecontract.com</a>. 
              </p>
              <p> <strong>Data Security</strong></p>
              <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any payment transactions will be encrypted using SSL technology.</p>
              <p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the site like message boards. The information you share in public areas may be viewed by any user of the site.</p>
              <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our site. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the site.</p>
              <p> <strong>Changes to Our Privacy Policy</strong></p>
              <p>It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal information, we will notify you by email to the email address specified in your account and/or through a notice on the site. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our site and this privacy policy to check for any changes.</p>
              <p> <strong>Contact Information</strong></p>
              <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at <a href="mailto:help@servicecontract.com">help@servicecontract.com</a> or <a herf="tel:18008003629">1-800-800-3629</a>.</p>
              </div>
            </div>
          </div>
        </section>
        {/* <NewsLatter /> */}
        <div className="break-section">
          <img src={lineImage} alt="line" />
        </div>
        <Footer />
      </Layout>
    );
  }
}


export default PrivacyPolicy
